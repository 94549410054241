<template>
<!-- 默认为 全部  的下拉选择框组件 -->
  <div class="mian">
    <el-select
      v-model="query.id"
       @change="change"
      placeholder="全部"
      style="width: 200px; height: 36px"
    >
      <el-option label="全部" value=""></el-option>
      <el-option
        v-for="(v, i) in list"
        :key="i"
        :label="v.name"
        :value="v.id"
       
      ></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default:[]
    },
  },
  data() {
    return {
      query: {
        id: "",
      },
    };
  },
  methods: {
    change(value) {
      console.log("公共组件选中的id为", value);
      this.$emit("selectId",value)
    },
  },
};
</script>
<style scoped>
.mian {
  display: inline-block;
  width: 200px;
  height: 36px;
}
</style>